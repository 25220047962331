import type { FC } from 'react'
import clsx from 'clsx'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  CwsA,
  CwsCounter,
  CwsGrid,
  CwsGridCol,
  CwsGridRow,
  CwsHeaderShoppingListItem,
  CwsHeaderShoppingListItemDeleteButton,
  CwsIcon,
  CwsSection,
  CwsP,
  CwsSpan,
} from '@components/cws'

import Image from 'next/future/image'

import type { CartItem } from '@model/cart/CartItem'
import type { Currency } from '@model/product/PriceInfo'
import { getFullImageUrl } from '@components/product/helpers'
import { useTranslate } from '@hooks/useTranslation'
import type { WishlistItem } from '@model/wishlist/WishlistItem'
import { useCart } from '@hooks/useCart'
import Link from 'next/link'
import debounce from 'lodash.debounce'
import type { CurrenciesInfo } from '@hooks/useCurrency'
import { getSlug } from '@utils/slugs'
import { getFormattedPrice, getMaxQuantity } from '@utils/price'
import { oncePerBasket } from '@utils/product-utils'
import type { Product } from '@model/product'
import { gaEventCart, UserData } from '@lib/gtm'
import { CustomFacetNumber } from '@model/product/CustomFacet'
import { getPromotionsTag } from '@components/product/ProductCard/ProductTagsRow/tags-utils'
import { useInitialData } from '@hooks/useInitialData'
import type { BundleGroups } from '@components/profile/Wishlist/Wishlist'
import { usePriceInfo } from '@hooks/usePriceInfo'
import { useSpecialPrice } from '@hooks/product'
import { setTypicalProductInfo } from '@utils/product/product-info'
import { KK_LANG_IDS, Locale } from '@model/locales'
import { useRouter } from 'next/router'
import { useAppState } from '@context/state'

export interface CartListItemProps {
  item: CartItem | WishlistItem
  currency: Currency
  isWishlist: boolean
  currencies: CurrenciesInfo[]
  bundleGroups?: BundleGroups
  quantityProductIsFree?: number
  setTotalCartPrices: (sku: string, price: number) => void
  isMobile?: boolean
}

export const CartListItem: FC<CartListItemProps> = ({
  item,
  currency,
  isWishlist = false,
  currencies,
  setTotalCartPrices,
  isMobile,
}) => {
  const { translate } = useTranslate()
  const { updateBasketItem, removeItem } = useCart('shopping cart')
  const { allPromotions } = useInitialData()
  const { locale } = useRouter()
  const productSlug = getSlug(item.product?.name, item.product?.sku)
  const [localQuantity, setLocalQuantity] = useState((item as CartItem).quantity || 0)
  let maxQuantity = getMaxQuantity(item.product.quantity)
  const isGWPProduct = item.product.sku.includes('GWP') && item.finalPriceExTax === 0
  const isGiftBoxOrGalaProduct = useMemo(() => oncePerBasket(item.product.sku), [item.product])
  const priceInfo = usePriceInfo(item.product, allPromotions)
  const { topPrice, normalPrice } = priceInfo
  const discountedPrice = normalPrice > topPrice ? topPrice : 0
  const enhancedProduct = setTypicalProductInfo(item.product, KK_LANG_IDS[locale as Locale], allPromotions)
  let { promoPrice } = enhancedProduct
  let specialPrice = (useSpecialPrice(item.product) && priceInfo.specialPrice) || promoPrice || 0
  if (discountedPrice && !specialPrice) specialPrice = discountedPrice
  const quantityWithBundle =
    priceInfo.bundleQuantity && localQuantity >= priceInfo.bundleQuantity
      ? localQuantity - priceInfo.bundleFreeProducts
      : localQuantity
  const rowFullPrice = (specialPrice || normalPrice) * quantityWithBundle
  if (isGiftBoxOrGalaProduct) {
    maxQuantity = 1
  }
  const {
    state: { customer },
  } = useAppState()

  const quantityEventHandler = useCallback(
    debounce(async (value: number) => {
      const { sku, productId, id } = item as CartItem
      await updateBasketItem({
        sku,
        productId,
        id,
        quantity: value,
        customerId: 0,
      })
      // setLocalQuantity((item as CartItem).quantity)
    }, 750),
    [updateBasketItem, item.sku, item.productId, item.id]
  )

  const removeHandler = useCallback(async () => {
    const { sku, productId, id } = item as CartItem
    await removeItem({ sku, productId, id, customerId: 0, quantity: 0 })
  }, [item, removeItem])
  const gaEventAddToCart = (product: Product, quantity: number) => {
    const campaign = product.customAttrArray?.find((attr) => attr.facetNumber === CustomFacetNumber.Campaign)?.value
    const label = product.customAttrArray?.find((attr) => attr.facetNumber === CustomFacetNumber.Label)?.value

    const { promotionName } = getPromotionsTag(allPromotions)(product.promotionResults)
    const badge = promotionName || campaign || label || ''
    const element = {
      item_name: product.name,
      item_id: product.sku,
      price: product.price0.toString(),
      discount: (product.price0 - item.finalPriceExTax).toFixed(2).toString(),
      currency: currency,
      promotion_name: translate(badge),
      item_brand: product.manufacturerName,
      item_category: '',
      item_category2: '',
      item_list_id: 'cart_modal',
      index: 1,
      quantity: '1',
    }
    const user: UserData | null = customer
      ? {
          user_email: customer.emailAddr,
          user_phone: customer.telephoneNumber,
          user_id: customer.id,
        }
      : null
    gaEventCart('add_to_cart', element, user, item.finalPriceExTax)
  }
  useEffect(() => {
    if (isWishlist) return
    setTotalCartPrices(item.sku, rowFullPrice)
  }, [localQuantity])
  if (!item.product) return null

  return (
    <CwsHeaderShoppingListItem key={item.id}>
      <CwsA />
      <CwsGrid width="fluid">
        <CwsGridRow noGutter>
          <CwsGridCol className="cws-mr-xs" col="auto">
            <Image
              alt={item.product.manufacturerName}
              width="75"
              height="100"
              className="object-contain aspect-[1/1]"
              loading="eager"
              src={getFullImageUrl(item.product.image)}
            />
          </CwsGridCol>
          <CwsGridCol>
            <CwsGridRow noGutter verticalAlign="center">
              <CwsGridCol className="cws-header__shopping-list-item-description" col="12" sm="auto">
                <Link href={`/product/${productSlug}`} passHref>
                  <CwsA className="cws-mb-4" hideArrow>
                    {item.product.manufacturerName}
                  </CwsA>
                </Link>
                <CwsP hideSpacing size="small">
                  {item.product.name}
                </CwsP>
              </CwsGridCol>
              <CwsGridCol col="12" sm="fluid">
                <CwsGridRow justifyContent="space-between" noGutter verticalAlign="center">
                  {!isWishlist && (
                    <CwsGridCol className={clsx('cws-mr-xs', isGWPProduct && 'invisible')} col="auto">
                      <CwsCounter
                        size="small"
                        insert
                        value={localQuantity}
                        min={0}
                        max={maxQuantity}
                        onChange={(value: number) => {
                          setLocalQuantity(value)
                          value > 0 && quantityEventHandler(value)
                          if (value > localQuantity) {
                            gaEventAddToCart(item.product, value)
                          }
                        }}
                      />
                    </CwsGridCol>
                  )}
                  {localQuantity !== 0 && !isGWPProduct && (
                    <CwsGridCol className={isWishlist && 'cws-ml-xl'} col="auto">
                      <CwsP hideSpacing weight="500">
                        {getFormattedPrice(rowFullPrice, currency)}
                      </CwsP>
                    </CwsGridCol>
                  )}
                  {isGWPProduct && (
                    <CwsGridCol className={isWishlist && 'cws-ml-xl'} col="auto">
                      <CwsP hideSpacing weight="500">
                        {translate('promotion.free.product.gift.row')}
                      </CwsP>
                    </CwsGridCol>
                  )}
                  {localQuantity === 0 && !isWishlist && (
                    <CwsGridCol col="auto" onClick={removeHandler}>
                      {!isMobile ? (
                        <CwsGridRow noGutter>
                          <CwsGridCol col="12" className=" flex justify-center items-center">
                            {/*<CwsSection onClick={removeHandler}>*/}
                            <CwsA color="error" hideArrow size="small" withIcon>
                              <CwsIcon className="cws-mr-6" icon="bin" size="16px" />
                            </CwsA>
                            {/*</CwsSection>*/}
                          </CwsGridCol>
                          <CwsGridCol col="12" className=" flex justify-center items-center">
                            <CwsA color="error" hideArrow size="small" withIcon>
                              <CwsSpan size="small" color="error" weight="500">
                                {translate('return.form.list.remove.child')}
                              </CwsSpan>
                            </CwsA>
                          </CwsGridCol>
                        </CwsGridRow>
                      ) : (
                        <CwsA color="error" hideArrow role="button" size="small" tabIndex="0" withIcon>
                          <CwsIcon icon="bin" size="16px" />
                          <CwsSpan weight="500">{translate('return.form.list.remove.child')}</CwsSpan>
                        </CwsA>
                      )}

                      {/*<CwsHeaderShoppingListItemDeleteButton onClick={removeHandler}>*/}
                      {/*  {translate('return.form.list.remove.child')}*/}
                      {/*</CwsHeaderShoppingListItemDeleteButton>*/}
                    </CwsGridCol>
                  )}
                </CwsGridRow>
              </CwsGridCol>
            </CwsGridRow>
          </CwsGridCol>
        </CwsGridRow>
      </CwsGrid>
    </CwsHeaderShoppingListItem>
  )
}

export default CartListItem
