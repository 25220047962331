import type { ChangeEvent, FC } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import { CwsAlert, CwsGridCol, CwsGridRow, CwsInput, CwsP } from '@components/cws'
import { useTranslate } from '@hooks/useTranslation'
import { useRouter } from 'next/router'
import { addressParser } from '@utils/maps-utils'
import { useCartState } from '@components/cart/State/state'

interface AddressInputWithSearchProps {
  zipValue: string
  onZipChange: (zip: string) => void
  setAddressString: React.Dispatch<React.SetStateAction<string>>
  addressString: string
}

export const AddressInputWithSearch: FC<AddressInputWithSearchProps> = ({
  zipValue,
  onZipChange,
  setAddressString,
  addressString,
}) => {
  const { locale } = useRouter()
  const { translate } = useTranslate()
  const { dispatch } = useCartState()

  const [zipInvalid, setZipInvalid] = useState<boolean>(false)

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setAddressString(e.target.value)
    },
    [setAddressString]
  )

  useEffect(() => {
    try {
      const center = { lat: 50.064192, lng: -130.605469 }
      const options = {
        bounds: {
          north: center.lat + 0.1,
          south: center.lat - 0.1,
          east: center.lng + 0.1,
          west: center.lng - 0.1,
        },
        componentRestrictions: { country: ['se'] },
        fields: ['address_components', 'name'],
        strictBounds: false,
        types: ['address'],
        language: locale,
        sessionToken: new google.maps.places.AutocompleteSessionToken(),
      }
      const input = document.getElementById('address-sv-only') as HTMLInputElement

      if (!input) throw new Error('input with the id not found')

      const autocomplete = new google.maps.places.Autocomplete(input, options)

      const handler = () => {
        const { address_components = [] } = autocomplete.getPlace()

        if (address_components.length) {
          // eslint-disable-next-line no-console
          // console.log({ ...addressParser(address_components), rawAddress: input.value })
          const { zip = '', street = '', city = '', streetNumber = '' } = addressParser(address_components)
          dispatch({
            type: 'setHomeDeliveryData',
            payload: { streetAddress: `${street} ${streetNumber}`, zip, city, allFilled: false },
          })
          setAddressString(input.value)
          if (zip) {
            onZipChange(zip)
            setZipInvalid(false)
          } else setZipInvalid(true)
        } else {
          onZipChange('')
          setZipInvalid(true)
        }
      }

      const listener = autocomplete.addListener('place_changed', handler)

      return () => {
        listener.remove()
      }
    } catch (e) {
      console.error(e)
      return () => {}
    }
  }, [locale, setAddressString, onZipChange, setZipInvalid])

  return (
    <CwsGridRow noGutter className="w-full">
      <CwsGridCol col="6">
        <CwsInput
          id="address-sv-only"
          label={translate('cart.confirmation.address')}
          onChange={handleChange}
          value={addressString}
          invalid={zipInvalid}
          placeHolder=""
        />
        {zipInvalid && <CwsP color="error">{translate('w2.zip.validation.error.message')}</CwsP>}

        <CwsAlert type="important" variant="inline" className="cws-my-10">
          <CwsP size="small">{translate('basket.courier.zip.help')}</CwsP>
        </CwsAlert>
        <div className="flex flex-row min-h-[2px] min-w-[35px] lg:min-w-[106px] lg:mb-8 mx-3 lg:mx-2"></div>
      </CwsGridCol>
    </CwsGridRow>
  )
}

export default React.memo(AddressInputWithSearch)
