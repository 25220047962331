import type { FC } from 'react'
import { useCallback } from 'react'
import Star from './Star'

export interface RatingStarsProps {
  filledCount: number
  count: number
}

const RatingStars: FC<RatingStarsProps> = (props) => {
  const { filledCount = 0, count = 5 } = props

  const renderStar = useCallback((filled: boolean, index: number) => {
    return <Star key={index} filled={filled} />
  }, [])

  if (filledCount === 0 && count === 0) return null

  return (
    <div className="flex flex-row space-x-px">
      {!!filledCount && new Array(Math.ceil(filledCount)).fill(true).map(renderStar)}
      {new Array(Math.ceil(count - filledCount)).fill(false).map(renderStar)}
    </div>
  )
}

export default RatingStars
