import { Typography } from '@ui/components/Typography'
import { FC } from 'react'
import RatingStars from './RatingStars'

export interface RatingProps {
  value: number
  maxValue?: number
  number?: number
  hideNumber?: boolean
}

const Rating: FC<RatingProps> = (props) => {
  const { value, maxValue = 5, hideNumber = false, number } = props
  return (
    <div className="flex flex-row items-center space-x-xs">
      <RatingStars filledCount={value} count={maxValue} />
      {!hideNumber && <Typography as="span">{`(${number})`}</Typography>}
    </div>
  )
}

export default Rating
