import type { FC } from 'react'
import React, { useMemo } from 'react'
import type { ClassName, OrderTotal } from '@model/order/OrderTotal'
import { useTranslate } from '@hooks/useTranslation'
import { PriceLine } from '@components/product'
import type { GiftCouponDataForRender } from '@components/cart/OrderDetails/OrderDetails'
import { useCartState } from '@components/cart/State/state'
import type { Action, CouponsData } from '@components/cart/State/reducer'
import { getPromotionsCouponCode } from '@utils/order'
import { Order } from '@model/order/Order'

type OtGetterList = (...className: ClassName[]) => OrderTotal[]
type OtGetter = (className: ClassName) => OrderTotal | undefined

interface PromoAndDiscountCodesProps {
  otGetter: OtGetter
  otGetterAll: OtGetterList
  order: Order | null
}

const clapProductDiscounts = (orderTotals: OrderTotal[]): CouponsData[] => {
  const map = orderTotals.reduce((acc, val) => {
    const couponFromPromotionCode = getPromotionsCouponCode(val) || val.custom3
    return {
      ...acc,
      [val.className]: {
        coupon: couponFromPromotionCode,
        balance: acc[val.className] ? acc[val.className].balance + val.value : val.value,
      },
    }
  }, {} as Record<ClassName, CouponsData>)

  return Object.values(map)
}

export const discountPromoCodesFactory = (otGetter: OtGetterList, dispatch: React.Dispatch<Action>) => () => {
  const discountOrderTotals = otGetter(
    'ot_df_global_product_discount',
    'ot_df_product_discount',
    'ot_df_total_discount',
    'ot_df_shipping_discount'
  )

  return clapProductDiscounts(discountOrderTotals).map((coupon) => ({
    ...coupon,
    remove: () => dispatch({ type: 'remDiscountCoupon', payload: coupon.coupon }),
  }))
}

export const giftPromotionsFactory =
  (otGetter: OtGetter, coupons: CouponsData[], dispatch: React.Dispatch<Action>) => () => {
    const giftCards = otGetter('ot_gift_coupons')
    const giftCardsTitle = giftCards?.title

    const re = new RegExp(/=\s(\S+):$/)
    const [, codes] = giftCardsTitle?.match(re) || []

    if (!codes) return []
    return codes.split(';').map((code) => {
      const couponData = coupons.find(({ coupon }) => code === coupon)

      if (couponData) {
        return {
          ...{ ...couponData, balance: giftCards?.value ?? couponData.balance },
          remove: () => dispatch({ type: 'remCoupon', payload: couponData.coupon }),
        }
      }
      return { coupon: code, balance: 0, remove: () => ({}) }
    })
  }

export const PromoAndDiscountCodes: FC<PromoAndDiscountCodesProps> = ({ otGetter, otGetterAll, order }) => {
  const { translate } = useTranslate()
  const {
    state: { discountCoupons, coupons },
    dispatch,
  } = useCartState()

  const discountPromoCodes: GiftCouponDataForRender[] = useMemo(discountPromoCodesFactory(otGetterAll, dispatch), [
    otGetter,
    dispatch,
  ])

  const giftCouponCodes: GiftCouponDataForRender[] = useMemo(giftPromotionsFactory(otGetter, coupons, dispatch), [
    otGetter,
    coupons,
    dispatch,
  ])

  const orderTotalsDiscounted = useMemo(
    () =>
      otGetterAll(
        'ot_df_global_product_discount',
        'ot_df_product_discount',
        'ot_df_total_discount',
        'ot_df_shipping_discount'
      ),
    [otGetterAll]
  )

  return (
    <>
      {otGetter('ot_gift_coupons') && <hr className="text-medium-grey border-dashed mb-4 mt-6" />}
      {otGetter('ot_gift_coupons') &&
        order &&
        giftCouponCodes.map(({ coupon, balance, remove }, index) => {
          const useBalance = balance > order?.totalIncTax ? order?.totalIncTax : balance
          return (
            <PriceLine
              key={index}
              label={`${translate('cart.summary.gift.cards.title')}: `}
              coupon={coupon}
              value={useBalance ? -1 * useBalance : useBalance}
              size="sm"
              labelClassName="text-sm"
              valueColor="midnight"
              valueClassName="flex flex-row"
              withRemoveIcon
              removeHandler={remove}
            />
          )
        })}

      {Boolean(orderTotalsDiscounted.length) && Boolean(discountCoupons.length) && (
        <>
          <hr className="text-medium-grey border-dashed my-4" />
          {discountPromoCodes.map(({ coupon, balance, remove }, index) => {
            if (!discountCoupons.includes(coupon)) return null

            return (
              <PriceLine
                key={index}
                label={`${translate('cart.promo.input.title')}: `}
                coupon={coupon}
                value={balance ? -1 * balance : balance}
                size="sm"
                labelClassName="text-sm"
                valueColor="midnight"
                valueClassName="flex flex-row"
                withRemoveIcon
                removeHandler={remove}
              />
            )
          })}
        </>
      )}
    </>
  )
}

export default PromoAndDiscountCodes
