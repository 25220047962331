import clsx from 'clsx'
import { FC } from 'react'

export interface StarProps {
  filled?: boolean
}

const Star: FC<StarProps> = (props) => {
  const { filled = false } = props
  return (
    <svg width="10" height="11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.611.953a.415.415 0 0 1 .778 0l.997 2.668a.415.415 0 0 0 .371.27l2.845.123c.384.017.541.501.24.74L7.615 6.527a.415.415 0 0 0-.142.436l.761 2.744a.415.415 0 0 1-.629.457L5.23 8.593a.415.415 0 0 0-.458 0l-2.375 1.571a.415.415 0 0 1-.63-.457l.762-2.744a.415.415 0 0 0-.142-.436L.157 4.754a.415.415 0 0 1 .24-.74l2.846-.124a.415.415 0 0 0 .37-.27L4.612.954z"
        className={clsx(filled ? 'text-midnight' : 'text-gray-300', 'fill-current')}
      />
    </svg>
  )
}

export default Star
