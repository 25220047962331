import {
  compose,
  includes,
  defaultTo,
  filter,
  find,
  flip,
  head,
  keys,
  last,
  map,
  pick,
  pipe,
  prop,
  propEq,
  propOr,
  sortBy,
  split,
} from 'ramda'
import { isAfter, isBefore } from 'date-fns'
import type { Promotion } from '@model/misc/Promotion'
import type { Product } from '@model/product'
import type { LanguageId } from '@model/locales'
import type { PromotionResult } from '@model/product/Product'
import {
  getCampaignLabelForProduct,
  getCoRateFromPromotions,
  isClubOnePrice,
  minimumValue,
} from '@utils/product/old-price-utils'
import { getParsedCustom8OrEmptyObject } from '@utils/product-data'

import productBundleConf from './product-bundle.json'
import type { PriceClass, PriceInfo } from '@model/product/PriceInfo'
import type { CurrencyOpt } from '@hooks/useCurrency'
import type { KK_STORE_TYPE } from '@pages/api/proxy'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import type { PromoDescription } from '@utils/promotion'

interface ProductBundleConf {
  [key: string]: {
    quantity: number
  }
}

const validJSON = function (input: string) {
  try {
    JSON.parse(input)
    return true
  } catch (e) {
    return false
  }
}

const languages = [
  { code: 'en', id: 1 },
  { code: 'et', id: 5 },
  { code: 'fi', id: 6 },
  { code: 'sv', id: 7 },
  { code: 'lv', id: 8 },
  { code: 'ru', id: 9 },
  { code: 'no', id: 10 },
  { code: 'lt', id: 11 },
]

const priceMinusPercentage = function (price: number, percent: number) {
  const resultPercent = percent || 0
  const resultPrice = price || 0
  return (resultPrice * (100 - resultPercent)) / 100
}

const percentage = function (discountPrice: number, originalPrice: number) {
  if (discountPrice === originalPrice || originalPrice === 0 || discountPrice === 0) return 0
  return Math.round((1 - discountPrice / originalPrice) * 100)
}

const isAlcoholItem = function (product: Product) {
  if (!product.url) return false

  const beverages = ['juomat', 'joogid', 'beverages', 'napitki', 'dzerieni', 'g-rimai', 'drycker']
  const urlModel = product.url.split('/')

  return includes(urlModel[0], beverages)
}

const isShop = function (storeId: KK_STORE_TYPE) {
  return storeId === 'store2'
}

const isLowStock = ({ quantity = 0, custom1Dec = 13, storeId = 'store2' }) => {
  if (!isShop(storeId as KK_STORE_TYPE)) return false
  return quantity <= custom1Dec + 3
}
const isSoldOut = ({ quantity = 0, custom1Dec = 10, storeId = 'store2' }) => {
  return quantity <= custom1Dec && isShop(storeId as KK_STORE_TYPE)
}
const isLimitedStock = function ({ quantity, storeId }: { quantity: number; storeId: KK_STORE_TYPE }) {
  return quantity <= 13 && isShop(storeId)
}

const pickLanguage = function (languageId: LanguageId): string {
  // @ts-ignore
  return pipe(find(propEq('id', languageId)), defaultTo(languages[0]), prop('code'))(languages)
}

const getPromoPriceData = function (product: Product, normalPrice: number, storeId: KK_STORE_TYPE) {
  const promoPriceData = {
    percent: 0,
    price: 0,
  }

  if (!getPromoPrice(product)) return promoPriceData

  let actPromo: PromotionResult | null

  try {
    // @ts-ignore
    actPromo = pipe(defaultTo([]), sortBy(compose(parseInt, prop('promotionId'))), last)(product.promotionResults)
  } catch (err) {
    actPromo = null
  }

  if (!actPromo) return promoPriceData

  const discountedPrice = actPromo.percentageDiscount
    ? priceMinusPercentage(normalPrice, actPromo.value)
    : normalPrice - actPromo.value

  if (discountedPrice > 0) {
    promoPriceData.percent = percentage(discountedPrice, normalPrice)
    promoPriceData.price = discountedPrice
  }

  return promoPriceData
}

const roundPrice = function (price: number): number {
  try {
    const matches = price.toString().match(/\d+\.\d{2}/)
    if (matches && matches.length) return parseFloat(matches[0])
    else return price
  } catch (e) {
    return price
  }
}

const parseCampaignDate = (input: string): Date => {
  const RegExpNamedCaptureGroups = /(?<day>[0-9]{2}).(?<month>[0-9]{2}).(?<year>[0-9]{4})/

  const {
    // @ts-ignore
    groups: { month, day, year },
  } = RegExpNamedCaptureGroups.exec(input)

  const date = new Date(Date.now())
  date.setDate(day)
  date.setMonth(month - 1)
  date.setFullYear(year)

  return date
}

type CampaignExpire = { campaign_from: string; campaign_to: string }

export const campaignActive = function (product: { custom4: string | null | undefined }): boolean {
  if (!product.custom4 || !validJSON(product.custom4)) return false

  const dates: CampaignExpire = JSON.parse(product.custom4)
  const from: string = dates.campaign_from
  const to: string = dates.campaign_to

  if (!from || !to) return false

  const nowMorning = new Date(Date.now())
  const nowEvening = new Date(Date.now())

  nowMorning.setHours(0, 0)
  nowEvening.setHours(23, 59)

  return isBefore(nowMorning, parseCampaignDate(to)) && isAfter(nowEvening, parseCampaignDate(from))
}

const usePromoPrice = function (product: Product) {
  return product.promotionResults && isShop(product.storeId)
}
const getPromoPrice = function (product: Product) {
  return product.promotionResults && isShop(product.storeId)
}

// const clearUnusedFields = function (product: Product) {
//   const values = [
//     'viewedCount',
//     'stockReorderLevel',
//     'priceIncTax',
//     'priceExTax',
//     'priceId',
//     'paymentScheduleId',
//     'ordered',
//     'maxNumDownloads',
//     'maxDownloadDays',
//     'ignoreProductQuantityStock',
//     'canOrderWhenNotInStock',
//   ]
//
//   return omit(values, product)
// }

export const defaultCurrencyOpt: CurrencyOpt = { currency: 'EUR', rate: 1 }

const discountPercentageMinimum = 1
const defaultRndRule = 0.01

function setBundleInfo(
  product: Product,
  prices: any,
  storeId: KK_STORE_TYPE,
  market: string,
  promotions: Promotion[],
  currencyOpt = defaultCurrencyOpt
): PriceInfo {
  const clfp = getCampaignLabelForProduct(product)
  // @ts-ignore
  const bundleKey: string = clfp ? find(flip(includes)(keys(productBundleConf)), clfp) : ''

  //pricelist logic
  if (!isShop(storeId) && bundleKey && prices.campaignPrice > 0) {
    prices.isBundle = true
    prices.bundleType = 'buy.x.for.special.price'
    prices.bundleLabel = bundleKey
    prices.bundleQuantity = (productBundleConf as ProductBundleConf)[bundleKey].quantity
    prices.bundleFreeProducts = 0
    prices.bundlePrice = prices.campaignPrice // price thats applied when user selects bundleQuantity
    prices.bundlePricePerItem = _normalRounding(prices.bundlePrice / prices.bundleQuantity, defaultRndRule)

    prices.bundleSavePercent = discountPercentage(prices.bundlePrice, prices.normalPrice * prices.bundleQuantity)
  }

  const promoFilter = (promo: Promotion) => {
    return promo.orderTotalCode === 'ot_dfshop_buy_x_get_y_free' && promo.active
  }

  promotions = filter(promoFilter, promotions)

  promotions.filter(promoFilter).forEach((promo) => {
    if (!promo.includedProducts || (promo.includedProducts && promo.includedProducts.length === 0)) return

    const promoCopy = { ...promo }
    const includedProductArray = promoCopy.includedProducts?.slice()
    const includedProduct = find(propEq('id', product.id))(includedProductArray || [])

    if (!includedProduct) return

    prices.isBundle = true
    prices.bundleType = promoCopy.name
    prices.bundleLabel = promoCopy.description
    prices.bundleQuantity = +promoCopy.custom1
    prices.bundleFreeProducts = +promoCopy.custom2

    prices.bundlePrice = (prices.bundleQuantity - prices.bundleFreeProducts) * prices.normalPrice
    prices.bundlePricePerItem = 0 // prices.bundlePrice / prices.bundleQuantity
    prices.bundleSavePercent = discountPercentage(prices.bundlePrice, prices.normalPrice * prices.bundleQuantity)
    prices.bundlePricePerItem = _normalRounding(prices.bundlePrice / prices.bundleQuantity, defaultRndRule)
    prices.bundleIncludedProducts = includedProductArray || []

    try {
      const { description = '' } = JSON.parse(promoCopy.description) as PromoDescription
      prices.bundleLabel = description
    } catch (e) {
      console.warn('Unable to parse promotion description')
    }
  })

  return prices
}

export function setPrice(priceValue = 0, { currency = 'EUR', rate = 1 } = {}, fullPrice = false) {
  const resultingPrice = priceValue * rate
  if (currency === 'SEK') {
    return Math.round(resultingPrice)
  }

  return resultingPrice
}

function useSpecialPrice(product: Product, storeId: KK_STORE_TYPE) {
  return isShop(storeId) && product.specialPriceExTax && campaignActive(product)
}

function getSpecialPrice(product: Product, storeId: KK_STORE_TYPE) {
  return storeId === 'store2' && product.specialPriceExTax && campaignActive(product)
}

function discountPercentage(discountPrice: number, originalPrice: number) {
  const percent = percentage(discountPrice, originalPrice)
  if (percent < discountPercentageMinimum || !percent) return 0
  return percent
}

function _normalRounding(price: number, rndRule: number) {
  let newPrice = 0
  const rnd = rndRule ? rndRule : 0.1

  newPrice = Math.round(price / rnd) * rnd
  newPrice = Math.floor(newPrice * 100 + 0.5) / 100 //+ 0.5 suppose to fix rounding issue

  return newPrice
}

function headCategoryInBeverages(product: Product) {
  const beverages = ['juomat', 'joogid', 'beverages', 'napitki', 'dzerieni', 'g-rimai', 'drycker']
  const inBeveragesList = flip(includes)(beverages)
  const inBeverages = compose(inBeveragesList, head, split('/'), propOr('', 'url'))

  return inBeverages(product)
}

const canShowNormalPriceForMarket = (market: string, inBeverages: boolean) => {
  return !(inBeverages && (market === 'et' || market === 'fi'))
}

function getPriceClass(prices: any, storeId: KK_STORE_TYPE): PriceClass {
  if (prices.isClubOnePrice) return 'club'
  if (prices.isBundle) return 'bundle'
  if (prices.topPrice < prices.normalPrice || (!isShop(storeId) && prices.campaignPrice > 0) || prices.specialPrice)
    return 'campaign'
  return 'default'
}

export function getClubOnePointsAvailable(price: number, rate: number, percentage: number) {
  if (price === 0 || percentage === 0) return 0
  const discountPercentage = percentage || 0
  return Math.round(((price * discountPercentage) / 100) * rate)
}

export function getPrices(
  product: Product,
  storeId: KK_STORE_TYPE,
  market: string,
  promotions: Promotion[],
  currencyOpt = defaultCurrencyOpt,
  coMultiplier = '1' // get from the user
): PriceInfo {
  const { orderProductPrice = 0 } = product
  let normalPrice = product.price0
  let promoPriceData = getPromoPriceData(product, normalPrice, storeId)
  let prices: PriceInfo = {
    currency: currencyOpt.currency,
    rate: currencyOpt.rate,
    coRate: getCoRateFromPromotions(promotions),

    topPrice: 0,
    topPriceShow: true,

    priceClass: 'default',

    normalPrice: setPrice(+(normalPrice ?? 0), currencyOpt, true),
    normalPriceShow: false,

    youSavePercent: 0,
    youSavePercentShow: false,

    youSaveAmount: 0,
    youSaveAmountShow: false,

    litrePrice: 0,
    litrePriceShow: false,

    ml100Price: 0,
    ml100PriceShow: false,

    isClubOnePrice: isClubOnePrice(product),
    clubOnePointsPercent: 0,
    clubOnePointsCanBeUsed: false,

    campaignPrice: campaignActive(product) ? setPrice(+(product.price2 ?? 0), currencyOpt, false) : 0,
    specialPrice: getSpecialPrice(product, storeId)
      ? setPrice(+(product.specialPriceExTax ?? 0), currencyOpt, false)
      : 0,
    promoPrice: setPrice(+(product.promoPrice ?? 0), currencyOpt, false),

    isBundle: false,
    bundleType: '',
    bundleLabel: '',
    bundleQuantity: 0,
    bundlePrice: 0,
    bundlePricePerItem: 0,
    bundleFreeProducts: 0,
    bundleSavePercent: 0,
    bundleIncludedProducts: [],

    groupPrices: pick(['min', 'max'], getParsedCustom8OrEmptyObject(product)),
  }
  let priceSelection = []

  priceSelection.push(prices.normalPrice)
  priceSelection.push(prices.specialPrice)

  if (isShop(storeId)) {
    priceSelection.push(prices.promoPrice)
    if (orderProductPrice) priceSelection.push(orderProductPrice)
  } else {
    priceSelection.push(prices.campaignPrice)
  }

  prices.topPrice = minimumValue(priceSelection)
  prices.youSavePercent = discountPercentage(prices.topPrice, prices.normalPrice)
  prices.youSaveAmount =
    prices.youSavePercent > 0 ? _normalRounding(prices.normalPrice - prices.topPrice, defaultRndRule) : 0

  // prices.litrePrice = setPrice(getLitrePrice(product, prices.topPrice), currencyOpt, true)
  // prices.ml100Price = setPrice(get100MlPrice(product, prices.topPrice), currencyOpt, true)

  const inBeverages = headCategoryInBeverages(product)

  // Visibility
  prices.youSavePercentShow =
    prices.youSavePercent > discountPercentageMinimum && canShowNormalPriceForMarket(market, inBeverages)
  prices.normalPriceShow = prices.youSavePercentShow
  // prices.litrePriceShow = showPricePerLitre(product)
  // prices.ml100PriceShow = showPricePer100Ml(product)
  // prices.landPriceShow =
  //   canShowLandPriceForMarket(market) && !prices.normalPriceShow && prices.landPrice > 0 && !isShop(storeId)

  prices.youSavePercentShow = false // just need to hide due to SHOP-3159

  prices.clubOnePointsPercent = product.custom7 ? parseInt(product.custom7) : 0
  prices.clubOnePointsPercent = prices.clubOnePointsPercent * parseFloat(coMultiplier)

  // if (coMultiplier === '1') {
  //   console.trace({ coMultiplier, percent: prices.clubOnePointsPercent })
  // }

  prices.clubOnePointsCanBeUsed = false

  // Check and add bundle
  prices = setBundleInfo(product, prices, storeId, market, promotions, currencyOpt)

  prices.priceClass = getPriceClass(prices, storeId)

  return prices
}

export const setTypicalProductInfo = function (product: Product, languageId: LanguageId, promotions: Promotion[]) {
  const findPromoDetails = function (promotionRow: PromotionResult) {
    const promo = find(propEq('id', promotionRow.promotionId))(promotions)

    if (promo) {
      promotionRow.promotionName = (promo as Promotion).name
      promotionRow.description = (promo as Promotion).description
    }
  }

  // Add promo information
  if (product.promotionResults && promotions) {
    map(findPromoDetails, product.promotionResults)
  }

  const language = pickLanguage(languageId)
  product.info = {
    store: product.storeId,
    showBasket: isShop(product.storeId),
    language,
    market: language,
    isLowStock: isLowStock(product),
    isSoldOut: isSoldOut(product),
    isLimitedStock: isLimitedStock(product),
    isAlcoholItem: isAlcoholItem(product),
  }

  if (!campaignActive(product)) {
    product.price2 = 0 // clear price to ignore it in later logic
    product.custom4 = null
  }

  const promoPriceData = getPromoPriceData(product, product.price0, isGloballyPricelist() ? 'store1' : 'store2')

  product.promoPrice = roundPrice(promoPriceData.price)

  return product
}

export const prepareMapper = (promotions: Promotion[], languageId: LanguageId) => (product: Product) =>
  setTypicalProductInfo(product, languageId, promotions)
