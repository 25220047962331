import type { Product } from '@model/product'
import type { Dispatch, FC, SetStateAction } from 'react'
import { range } from 'ramda'
import { CwsCounter, CwsP } from '@components/cws'
import type { ItemsQuantityState } from '@hooks/useMultipleItemsQuantity'
import { BundleItem } from '@components/product/BundleOptions/BundleItem'
import Image from 'next/future/image'
import { getFullImageUrl } from '@components/product/helpers'
import { getMaxQuantity } from '@utils/price'
import moduleStyles from './Bundle.module.scss'
import { BundleTypeTab } from '@components/product/ProductViewParameters/ProductViewParameters'
import { useEffect } from 'react'

export type SelectedProductValue = { product: Product; quantity: number } | null

interface BundleOptionsProps {
  bundleQuantity: number
  itemQuantityCallback(sku: string, value: number): void
  quantityState: ItemsQuantityState
  unUsedProducts: Product[]
  setUnUsedProducts: Dispatch<SetStateAction<Product[]>>
  selectedMap: Record<number, SelectedProductValue>
  remProduct: (place: number) => void
  setSelectedMap: Dispatch<SetStateAction<Record<number, SelectedProductValue>>>
  setTab: Dispatch<SetStateAction<BundleTypeTab>>
}

const OneProduct: FC<{
  item: { product: Product; quantity: number }
  value: number
  selectNumber: number
  handleRemove(): void
  itemQuantityCallback(sku: string, value: number): void
}> = ({ item: { product }, itemQuantityCallback, value, handleRemove, selectNumber }) => {
  return (
    <div className="flex flex-row justify-between items-center py-2 border-dotted border-b-2">
      <Image
        src={getFullImageUrl(product?.image)}
        alt={product?.name}
        className={moduleStyles.bundleImage}
        width={64}
        height={64}
      />
      <div className="w-1/3">
        <CwsP hideSpacing weight="500" className="truncate">
          {product.name}
        </CwsP>
      </div>
      <div className="w-1/3 flex justify-center">
        <CwsCounter
          size="large"
          value={value}
          min={1}
          max={getMaxQuantity(product.quantity)}
          insert
          onChange={(value: number) => {
            itemQuantityCallback(product.sku, value)
          }}
        />
      </div>
    </div>
  )
}

export const BundleOptions: FC<BundleOptionsProps> = ({
  selectedMap,
  bundleQuantity,
  remProduct,
  itemQuantityCallback,
  quantityState,
  unUsedProducts,
  setUnUsedProducts,
  setSelectedMap,
  setTab,
}) => {
  useEffect(() => {
    setTab('bundle')
  }, [])
  return (
    <section className="w-full">
      <ol>
        {Object.keys(selectedMap).map((index) => {
          if (+index === 0) {
            const item = selectedMap[+index]
            if (item !== null) {
              return (
                <li key={index}>
                  <OneProduct
                    item={item}
                    handleRemove={() => remProduct(+index)}
                    itemQuantityCallback={itemQuantityCallback}
                    value={quantityState[item.product.sku] || 1}
                    selectNumber={+index}
                  />
                </li>
              )
            }
          }
          return (
            <BundleItem
              selectNumber={+index}
              product={selectedMap[+index]?.product}
              key={selectedMap[+index]?.product?.sku || index}
              itemQuantityCallback={itemQuantityCallback}
              bundleIncludedProducts={unUsedProducts}
              quantityState={quantityState}
              setUnUsedProducts={setUnUsedProducts}
              setSelectedMap={setSelectedMap}
            />
          )
        })}
      </ol>
    </section>
  )
}
