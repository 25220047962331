import type { DetailedHTMLProps, FC, HTMLAttributes, PropsWithChildren } from 'react'
import { useCallback, useMemo, useState } from 'react'
import type { ImageProps } from 'next/image'
import Link from 'next/link'
import clsx from 'clsx'
import { Badge, PriceLine, ProductImage, Rating } from '@components/product'
import type { ProductItemBaseProps } from '../types'
import { extractTitle, getFullImageUrl } from '../helpers'
import s from './ProductCard.module.scss'
import { AppRoutes } from 'app/routes'
import { ProductOverlappingColors } from '../ProductOverlappingColors'
import { useCart } from '@hooks/useCart'
import { getFormattedPrice } from '@utils/price'
import { CustomFacetNumber } from '@model/product/CustomFacet'
import { useSpecialPrice } from '@hooks/product'
import { useCurrency } from '@hooks/useCurrency'
import { usePriceInfo } from '@hooks/usePriceInfo'
import { useTranslate } from '@hooks/useTranslation'
import { CwsA, CwsButton, CwsDivider, CwsP, CwsSpan } from '@components/cws'
import { ProductTagsRow } from '@components/product/ProductCard'
import { getFreeProductInfo } from '@utils/product/old-price-utils'
import { useInitialData } from '@hooks/useInitialData'
import { setTypicalProductInfo } from '@utils/product/product-info'
import { useRouter } from 'next/router'
import type { Locale } from '@model/locales'
import { KK_LANG_IDS } from '@model/locales'
import ImagePlaceholder from '@components/product/ProductImage/assets/placeholder.svg'
import { getPriceValueWithCurrency } from '@utils/currency'
import { getSlug } from '@utils/slugs'
import { isSkuForbiddenToAddToCart, oncePerBasket } from '@utils/product-utils'
import { cartIncludesGalaTicket } from '@components/product/ProductViewParameters/ProductViewParameters'
import type { Product } from '@model/product'
import { CartEvent, gaEventCart, UserData } from '@lib/gtm'
import { isGloballyPricelist } from '@utils/pricelist-utils/app-properties'
import { useWishlist } from '@hooks/useWishlist'
import { useAlert } from '@context/alert'
import { isCampaignValid } from '@utils/campaign-utils'
import { getPromotionsTag } from '@components/product/ProductCard/ProductTagsRow/tags-utils'
import * as R from 'ramda'
import { useAppState } from '@context/state'

const ProductCard: FC<ProductItemBaseProps> = (props) => {
  const { product, className, listName, index } = props

  const {
    manufacturerName,
    name: title,
    rating: ratingValue = 0,
    price0: price,
    sku,
    variantInfo,
    id,
    image: imageRelativePath,
    customAttrArray: customAttrs,
    numberReviews,
    quantity,
    status,
    promotionResults,
    custom4: campaignValidityData,
  } = product

  const {
    locale,
    query: { catalogId = '' },
  } = useRouter()
  const {
    state: { customer },
  } = useAppState()

  const isPricelist = isGloballyPricelist()
  const { allPromotions, currencies } = useInitialData()
  const priceInfo = usePriceInfo(product, allPromotions)

  const enhancedProduct = setTypicalProductInfo(product, KK_LANG_IDS[locale as Locale], allPromotions)

  const image = useMemo(() => getFullImageUrl(imageRelativePath?.replace('_big', '_medium')), [imageRelativePath])

  const [imgLoaded, setImgLoaded] = useState(false)

  const { showAlert } = useAlert()

  const { addToCartWithSideEffects } = useCart(isPricelist ? 'shopping list' : 'shopping cart')
  const { addToWishlist, wishlist } = useWishlist()

  const handleAddAction = async () => {
    if (isPricelist) {
      const alreadyAdded = R.compose(R.propOr(0, 'quantityDesired'), R.find(R.propEq('productId', id)))(wishlist)
      const quantityDesired = 1 + (alreadyAdded as number)

      const added = await addToWishlist({
        productId: id,
        product: {
          sku,
        },
        quantityDesired,
      })
      gaEventAddToWishlist(product)

      if (added) {
        showAlert({ text: translate('s2.wishlist.items.added') as string, hideDelay: 4000 })
      } else {
        showAlert({ error: true, text: translate('basket.items.add.error.general') as string, hideDelay: 4000 })
      }
    } else {
      await addToCartWithSideEffects({ productId: id, sku, quantity: 1 })
      gaEventAddToCart(product)
    }
  }

  const calculatedTitle = extractTitle(product)

  const handleImageLoadingComplete = useCallback<Required<ImageProps>['onLoadingComplete']>(() => {
    setImgLoaded(true)
  }, [])

  const { translate } = useTranslate()

  const LinkWrap = useCallback(
    ({
      children,
      anchorProps,
    }: PropsWithChildren<{
      anchorProps?: DetailedHTMLProps<HTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>
    }>) => {
      const productSlug = getSlug(title, sku)
      const href = catalogId
        ? `/${catalogId}${AppRoutes.productDetails.get(productSlug)}`
        : AppRoutes.productDetails.get(productSlug)
      return (
        <span
          onClick={() => {
            gaEventSelectItem()
          }}
        >
          <Link href={href} passHref>
            <a {...anchorProps}>{children}</a>
          </Link>
        </span>
      )
    },
    [sku, title, catalogId]
  )

  const LinkWrapWithoutAnchor = useCallback(
    ({ children }: PropsWithChildren) => {
      const productSlug = getSlug(title, sku)

      const href = catalogId
        ? `/${catalogId}${AppRoutes.productDetails.get(productSlug)}`
        : AppRoutes.productDetails.get(productSlug)
      return (
        <span
          onClick={() => {
            gaEventSelectItem()
          }}
        >
          <Link href={href} passHref>
            {children}
          </Link>
        </span>
      )
    },
    [sku, title, catalogId]
  )

  const colorsImages = useMemo(
    () =>
      (variantInfo?.type?.toLowerCase().includes('color') &&
        variantInfo.variants?.map((variant) => variant.colorImg ?? '')) ??
      [],
    [variantInfo]
  )

  const campaign = customAttrs
    ?.filter((attr) => attr.facetNumber === CustomFacetNumber.Campaign)
    .map(({ value }) => value)
  const label = customAttrs?.find((attr) => attr.facetNumber === CustomFacetNumber.Label)?.value
  const { promotionId: giftPromotionId } = getFreeProductInfo(product)
  let { bundleLabel = '', bundleType } = priceInfo

  if (campaign && campaign.includes(bundleLabel)) {
    bundleLabel = ''
  } else if (bundleLabel && bundleLabel.includes('.')) {
    bundleLabel = translate(bundleLabel)
  }

  const isCampaignValidFlag = isCampaignValid(campaignValidityData)
  const isOutOfStock = !(product.sku.search('VAR') > 1) && (!quantity || quantity < 1)
  const isDisabled = status === 0

  const { currency } = useCurrency()

  let { promoPrice, info } = enhancedProduct
  const { normalPrice, topPrice } = priceInfo
  const discountedPrice = normalPrice > topPrice ? topPrice : 0

  const isAlcoholItem = info?.isAlcoholItem || false
  const hideNormalPrice = isAlcoholItem && product.custom3 && +product.custom3 > 0
  const { cart: cartItems = [] } = useCart('shopping cart')

  const isGalaTicketInBasket = useMemo(
    () => oncePerBasket(sku) && Array.isArray(cartItems) && Boolean(cartIncludesGalaTicket(cartItems)),
    [cartItems, sku]
  )

  if (promoPrice) promoPrice = getPriceValueWithCurrency(promoPrice, currency, currencies)

  let specialPrice = (useSpecialPrice(product) && priceInfo.specialPrice) || promoPrice
  if (discountedPrice && !specialPrice) specialPrice = discountedPrice

  // const isActiveCampaign = isGloballyPricelist() ? !!campaign : isCampaignValidFlag && campaign
  const isActiveCampaign = campaign && !!campaign.length

  const gaEvent = (product: Product, eventName: CartEvent) => {
    const productUrlArr = product.url.split('/')
    const discount = normalPrice - (specialPrice || normalPrice)
    const category = productUrlArr[1]
    const category2 = productUrlArr[2]
    const { promotionName } = getPromotionsTag(allPromotions)(product.promotionResults)
    const badge = promotionName || campaign.join(' ') || bundleLabel || label || ''
    const item = {
      item_name: product.name,
      item_id: product.sku,
      price: normalPrice.toString(),
      discount: discount ? discount.toFixed(2).toString() : '',
      currency: currency,
      promotion_name: translate(badge),
      item_brand: product.manufacturerName,
      item_category: category ?? '',
      item_category2: category2 ?? '',
      item_list_id: listName,
      index: index ?? 1,
      quantity: '1',
    }
    const user: UserData | null = customer
      ? {
          user_email: customer.emailAddr,
          user_phone: customer.telephoneNumber,
          user_id: customer.id,
        }
      : null
    gaEventCart(eventName, item, user, specialPrice || normalPrice)
  }
  const gaEventAddToCart = (product: Product) => {
    gaEvent(product, 'add_to_cart')
  }
  const gaEventAddToWishlist = (product: Product) => {
    gaEvent(product, 'add_to_wishlist')
  }
  const gaEventSelectItem = () => {
    gaEvent(product, 'select_item')
  }
  const discount = useMemo(() => {
    return enhancedProduct.promotionResults
      ? enhancedProduct.promotionResults.find((item) => item.orderTotalCode === 'ot_df_product_discount')?.value
      : null
  }, [enhancedProduct.promotionResults])

  const discountValue = useMemo(() => {
    if (discount) {
      return `-${discount}%`
    }
    return priceInfo.youSavePercent ? `-${priceInfo.youSavePercent}%` : ''
  }, [priceInfo, discount])

  return (
    // <div className={clsx(s.box, className, quantity < 2 ? 'opacity-100' : 'opacity-20')}>

    <div className={clsx(s.box, (isOutOfStock || isDisabled) && 'opacity-50', className)} data-testid="product-card">
      <div className="flex flex-col space-y-lg">
        <ProductTagsRow
          giftPromotionId={giftPromotionId}
          campaign={isActiveCampaign ? campaign : undefined}
          label={label}
          bundleLabel={isPricelist ? bundleLabel : bundleType}
          promotionResults={promotionResults}
        />
        <LinkWrap anchorProps={{}}>
          {imageRelativePath ? (
            <ProductImage src={image} width={195} height={130} alt={product.sku} loading="eager" />
          ) : (
            <span className={s.defaultImage}>
              <ImagePlaceholder width={195} height={130} className="object-contain" />
            </span>
          )}
        </LinkWrap>
      </div>

      <span className={s['title-section']}>
        <CwsSpan color="midnight">{manufacturerName}</CwsSpan>
        <LinkWrap>
          <CwsP size="p" weight="500">
            {calculatedTitle}
          </CwsP>
        </LinkWrap>

        {!!ratingValue && <Rating value={ratingValue} hideNumber={!numberReviews} number={numberReviews} />}
      </span>

      {colorsImages && colorsImages.length > 0 && (
        <div className="mx-auto">
          <ProductOverlappingColors colorsImages={colorsImages} />
        </div>
      )}

      <CwsDivider variant="dashed" spacing="none" />
      <div>
        {!!specialPrice ? (
          <>
            <div className="flex gap-x-2 items-baseline">
              <PriceLine
                label={translate('w2.product.tile.price.normal') as string}
                value={specialPrice}
                size="sm"
                labelClassName="text-sm"
                valueColor={'notice'}
              />
              {!hideNormalPrice && isGloballyPricelist() && <Badge color="notice">{discountValue}</Badge>}
            </div>
            {!hideNormalPrice && (
              <div className="flex flex-row justify-between">
                <CwsP size="small" color="dark-grey">
                  {translate('wishlist.email.price.normal')}:
                </CwsP>
                <CwsP size="small" color="dark-grey">
                  {getFormattedPrice(normalPrice, currency)}
                </CwsP>
              </div>
            )}
          </>
        ) : (
          <PriceLine
            label={translate('w2.product.tile.price.normal') as string}
            value={normalPrice}
            size="sm"
            labelClassName="text-sm"
            valueColor={'midnight'}
          />
        )}
      </div>
      <CwsDivider variant="dashed" spacing="none" />

      {isPricelist && (
        <div className="flex flex-col space-y-xs mt-auto product-options place-items-center gap-4 ">
          <CwsButton onClick={handleAddAction} width="fluid" size="medium" variant="secondary">
            {translate('product.tile.add.to.shopping.list')}
          </CwsButton>
          <LinkWrapWithoutAnchor>
            <CwsA color="buoy-link" href={'#'}>
              {translate('order.history.details.open.details')}
            </CwsA>
          </LinkWrapWithoutAnchor>
        </div>
      )}

      {!isPricelist && (
        <div className="flex flex-col space-y-xs mt-auto product-options place-items-center gap-4 ">
          {isOutOfStock || isDisabled ? (
            <>
              <CwsButton variant="secondary" size="medium" width="fluid">
                {translate('product.tile.out.of.stock')}
              </CwsButton>
              <LinkWrapWithoutAnchor>
                <CwsA color="buoy-link" href={'#'}>
                  {translate('order.history.details.open.details')}
                </CwsA>
              </LinkWrapWithoutAnchor>
            </>
          ) : (
            <>
              <CwsButton
                disabled={isGalaTicketInBasket}
                onClick={handleAddAction}
                className={clsx(isSkuForbiddenToAddToCart(sku) && 'invisible')}
                size="medium"
                variant="secondary"
                width="fluid"
              >
                {translate('common.add.to.cart')}
              </CwsButton>

              <LinkWrapWithoutAnchor>
                <CwsA color="buoy-link" href={'#'}>
                  {translate('order.history.details.open.details')}
                </CwsA>
              </LinkWrapWithoutAnchor>
            </>
          )}
        </div>
      )}
    </div>
  )
}

export default ProductCard
