import type { FC } from 'react'
import { CwsAlert, CwsGrid, CwsGridCol, CwsGridRow, CwsP, CwsSection } from '@components/cws'
import clsx from 'clsx'

interface InfoRowProps {
  primaryInfo?: string
  secondaryInfo?: string
  variant: 'error' | 'success' | 'info' | 'important'
  inner?: boolean
  html?: boolean
}

export const InfoRow: FC<InfoRowProps> = ({ primaryInfo, secondaryInfo, variant, inner = false, html = false }) => {
  return (
    <CwsSection className={clsx('max-w-full cws-mb-xs cws-pt-m', !inner && 'lg:mx-[32px]')}>
      <CwsAlert border type={variant}>
        <CwsGrid width="fluid">
          <CwsGridRow noGutter verticalAlign="center">
            {/*<CwsGridCol className="cws-mr-24" col="auto">*/}
            {/*  <CwsIcon icon={icon} size="32px" />*/}
            {/*</CwsGridCol>*/}
            <CwsGridCol>
              <CwsGridRow noGutter verticalAlign="center">
                <CwsGridCol className="cws-pb-0-sm cws-pr-24-sm">
                  {!html && (
                    <CwsP hideSpacing size="big">
                      {primaryInfo}
                    </CwsP>
                  )}
                  {html && <CwsP dangerouslySetInnerHTML={{ __html: primaryInfo }} hideSpacing size="big" />}
                  {secondaryInfo !== 'value.ribbon.text2' && (
                    <CwsP hideSpacing size="small">
                      {secondaryInfo}
                    </CwsP>
                  )}
                </CwsGridCol>
              </CwsGridRow>
            </CwsGridCol>
          </CwsGridRow>
        </CwsGrid>
      </CwsAlert>
    </CwsSection>
  )
}
