import { FC } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import s from './ProductOverlappingColors.module.scss'

export interface ProductOverlappingColorsProps {
  colorsImages: string[]
}

const ProductOverlappingColors: FC<ProductOverlappingColorsProps> = (props) => {
  const { colorsImages } = props

  const colorsCount = colorsImages.length

  return (
    <div className="flex flex-row">
      {colorsImages.map((colorImage, index) => {
        return (
          <Image
            key={index}
            layout="fixed"
            src={colorImage}
            alt=""
            width={20}
            height={20}
            className={clsx('rounded-full bg-group-grey', `z-[${colorsCount + 1 - index}]`, s.outline)}
          />
        )
      })}
      {/* <div className={clsx('w-5 h-5 bg-buoy rounded-full z-10', s.outline)}></div>
      <div className={clsx('w-5 h-5 bg-jade rounded-full z-[9] -ml-0.5', s.outline)}></div>
      <div className={clsx('w-5 h-5 bg-pink-600 rounded-full z-[8] -ml-0.5', s.outline)}></div>
      <div className={clsx('w-5 h-5 bg-group-grey rounded-full z-[7] -ml-0.5', s.outline)}></div> */}
    </div>
  )
}

export default ProductOverlappingColors
