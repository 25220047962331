import type { ChangeEvent, FC } from 'react'
import { Fragment, useMemo } from 'react'
import { useEffect, useRef, useState } from 'react'
import { useClickAway } from '@ui/hooks/useClickAway'
import s from './SelectSearch.module.scss'
import { CwsDivider, CwsIcon, CwsSpan } from '@components/cws'
import clsx from 'clsx'

export interface OptionsSelect {
  id: string
  name: string
}
interface SelectSearchProps {
  options: OptionsSelect[]
  onChange: (value: string) => void
  value: string
  disabled?: boolean
  placeholder?: string
}

const SelectSearch: FC<SelectSearchProps> = ({ options, onChange, value, disabled, placeholder }) => {
  const [isFocus, setIsFocus] = useState(false)
  const selectRef = useRef<HTMLDivElement>(null)

  const handleOutSideClick = () => {
    setIsFocus(false)
  }
  useClickAway(selectRef, handleOutSideClick)

  const initialValue = useMemo(() => options.find((option) => option.id === value), [options, value])
  const [searchValue, setSearchValue] = useState<string | undefined>(initialValue?.name)

  const [currentOptions, setCurrentOption] = useState<OptionsSelect[]>(options)

  useEffect(() => {
    setCurrentOption(options)
  }, [options])

  useEffect(() => {
    if (!initialValue) setSearchValue('')
  }, [initialValue])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
    if (!e.target.value) {
      setCurrentOption(options)
      return
    }

    const optionsFilter = options?.filter((item) => {
      return item.name.toLowerCase().includes(e.target.value.toLowerCase())
    })

    setCurrentOption(optionsFilter ?? [])
  }
  const handleChange = (option: OptionsSelect) => {
    setIsFocus(false)
    setSearchValue(option.name)
    onChange(option.id)
  }
  return (
    <div className={clsx(s.selectSearch, disabled && s.disabled)} ref={selectRef}>
      <div className={s.selectInputWrapper}>
        <input
          className={s.selectInput}
          disabled={disabled}
          type="text"
          value={searchValue}
          onChange={handleSearch}
          onFocus={() => setIsFocus(true)}
          placeholder={placeholder}
        />
        <span className="cursor-pointer" onClick={() => setIsFocus((prevState) => !prevState)}>
          {isFocus ? <CwsIcon icon="arrow-up" size="16px" /> : <CwsIcon icon="arrow-down" size="16px" />}
        </span>
      </div>

      {isFocus && !!currentOptions.length && (
        <div className={s.selectOptions}>
          {currentOptions?.map((item) => (
            <Fragment key={item.id}>
              <div
                className={clsx(item.id === value && s.active, s.option)}
                key={item.id}
                onClick={() => handleChange(item)}
              >
                <CwsSpan className="cws-px-16 cws-pt-8" weight={500} color={'midnight'}>
                  {item.name}
                </CwsSpan>
              </div>
              <CwsDivider className="cws-mr-0 cws-ml-0" />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default SelectSearch
