import { compose, ifElse, tryCatch, always, defaultTo, propOr, anyPass, identity, has, tap } from 'ramda'
import { Product } from '@model/product'
import { Variant, VariantInfo } from '@model/product/VariantsInfo.js'

export const getParsedCustom8OrEmptyObject = (product: Product) => {
  const emptyVariant = {}

  //todo: add tests here and remove the ts-ignore
  // @ts-ignore
  const app: (p: Product) => VariantInfo = compose(
    ifElse(anyPass([has('id'), has('min')]), identity, always(emptyVariant)),
    tryCatch((json: string) => JSON.parse(json), always(emptyVariant)),
    defaultTo('') as () => string,
    propOr('', 'custom8')
  )
  const parsedCustom8 = app(product)

  parsedCustom8.variants?.forEach((x: Variant) => {
    if (x.size) x.size = x.size.trim()
  })

  if (parsedCustom8 && parsedCustom8.type) {
    parsedCustom8.hideColorSelection =
      parsedCustom8.type &&
      (parsedCustom8.type.toLowerCase() === 'size-all' || parsedCustom8.type.toLowerCase() === 'size-parent')
  }

  return parsedCustom8
}

export const disabledProductsPredicate = (product: Product) => {
  const isOutOfStock = !product.quantity || product.quantity < 1
  const isDisabled = product.status === 0
  const shouldHide = isOutOfStock || isDisabled

  return !shouldHide
}
