import type { Currency } from '@model/product/PriceInfo'
import type { CurrenciesInfo } from '@hooks/useCurrency'
import * as R from 'ramda'
import { getFormattedPrice } from '@utils/price'

export const getPriceWithCurrency = (
  price: number,
  currency: Currency,
  currencies: CurrenciesInfo[],
  priceCurrency?: Currency
) => {
  const currentCurrencyValue = R.compose(
    R.propOr(1, 'value'),
    R.find<CurrenciesInfo>(({ code }) => code === currency)
  )(currencies) as number

  if (priceCurrency === 'SEK') {
    const currentPrice = currency === 'EUR' ? revertBackToEURCurrency(price, currencies) : price
    return getFormattedPrice(currentPrice, currency)
  }
  return getFormattedPrice(currency === 'SEK' ? Math.floor(currentCurrencyValue * price) : price, currency)
}

export const getPriceValueWithCurrency = (price: number, currency: Currency, currencies: CurrenciesInfo[]) => {
  const currentCurrencyValue = R.compose(
    R.propOr(1, 'value'),
    R.find<CurrenciesInfo>(({ code }) => code === currency)
  )(currencies) as number

  return currency === 'SEK' ? Math.round(currentCurrencyValue * price) : price
}

export const revertBackToEURCurrency = (price: number, currencies: CurrenciesInfo[]) => {
  const currentCurrencyValue = R.compose(
    R.propOr(1, 'value'),
    R.find<CurrenciesInfo>(({ code }) => code === 'SEK')
  )(currencies) as number

  return Math.round(price / currentCurrencyValue)
}
