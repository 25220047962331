import { CwsA, CwsP } from '@components/cws'
import Link from 'next/link'
import type { FC } from 'react'
import { Clubone as CluboneSvg } from './assets'
import { clickSSOButton } from '@utils/with-dom'
import clsx from 'clsx'
import { useTranslate } from '@hooks/useTranslation'

interface CoLoginBannerProps {
  onPaymentView?: boolean
}

export const CoLoginBanner: FC<CoLoginBannerProps> = ({ onPaymentView }) => {
  const { translate } = useTranslate()

  const discountText = translate('w2.discount.text', {
    signIn: `<a class="cws-a cws-a--theme-white cws-a--size-tiny cws-a--inline cws-a--color-dark-grey" rel="noopener noreferrer" href="/">${translate(
      'w2.sign.in'
    )}</a>`,
    joinCubOne: `<a class="cws-a cws-a--theme-white cws-a--size-tiny cws-a--inline cws-a--color-dark-grey" rel="noopener noreferrer"  href="https://ee.tallink.com/club-one">${translate(
      'w2.join.club.one'
    )}</a>`,
  })
  return (
    <section className={clsx(!onPaymentView && 'bg-group-grey', 'flex flex-row px-4 py-4 space-x-lg')}>
      <div>
        <CluboneSvg width="52px" height="52px" />
      </div>
      <CwsP size="small" dangerouslySetInnerHTML={{ __html: discountText }} />
    </section>
  )
}

export default CoLoginBanner
