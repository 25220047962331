import type { Color } from '@ui/system'
import type { FC, PropsWithChildren } from 'react'
import { CwsTag } from '@components/cws'
import moduleStyles from './Badge.module.scss'

export interface BadgeProps {
  size?: 'sm'
  color?: Color
}

const Badge: FC<PropsWithChildren<BadgeProps>> = (props) => {
  const { color = 'midnight', children } = props
  return (
    <span className={moduleStyles.tag}>
      <CwsTag color="white" backgroundColor={color} type="regular" variant="label" size="small">
        {children}
      </CwsTag>
    </span>
  )
}

export default Badge
