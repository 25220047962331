import clsx from 'clsx'
import { CwsSpan, CwsP, CwsIcon, CwsA } from '@components/cws'
import type { FC, HTMLAttributes } from 'react'
import s from './PriceLine.module.scss'
import type { Color } from '@ui/system'
import { useCurrency } from '@hooks/useCurrency'
import { getFormattedPrice } from '@utils/price'
import { useTranslate } from '@hooks/useTranslation'

type StyleKey = keyof typeof s
export interface PriceLineProps extends HTMLAttributes<HTMLDivElement> {
  label: string
  value: number
  dotted?: boolean
  size?: 'sm' | 'md'
  labelClassName?: string
  labelColor?: string
  valueClassName?: string
  valueColor?: Color
  withRemoveIcon?: boolean
  removeHandler?: () => void
  coPoints?: boolean
  coupon?: string
}

export const PriceLine: FC<PriceLineProps> = (props) => {
  const {
    label,
    value,
    dotted = false,
    size = 'md',
    labelClassName,
    valueClassName,
    className,
    valueColor = 'midnight',
    withRemoveIcon = false,
    removeHandler,
    labelColor = 'midnight',
    coPoints,
    coupon,
  } = props

  const { currency } = useCurrency()
  const { translate } = useTranslate()

  return (
    <div className={clsx(s.container, s[size as StyleKey], className ?? 'items-baseline')}>
      <CwsP className={clsx(dotted && s.dotted, labelClassName)} size="small" color={labelColor}>
        {label}
        {coupon && <span className="border-dotted border-midnight border-b-2">{coupon}</span>}
      </CwsP>
      <CwsSpan className={clsx(s.value, valueClassName)} color={valueColor}>
        {coPoints ? `${value} ${translate('profile.details.clubone.info.pts')}` : getFormattedPrice(value, currency)}
        {withRemoveIcon && (
          <CwsA textAlign="right" color="error" hideArrow onClick={removeHandler} withIcon size="small">
            <CwsIcon className="cws-ml-6" icon="bin" size="16px" color="error" />
          </CwsA>
        )}
      </CwsSpan>
    </div>
  )
}

export default PriceLine
