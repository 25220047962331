import { compose, isNil, not } from 'ramda'

export const isNotNil = compose(not, isNil)

export const dummyPromiseRejectHandler = (reason: any) => {
  console.error(reason)
}

export const isNowBetweenTwoDates = (startDate: number, endDate: number): boolean => {
  const now = Date.now()
  return startDate <= now && now <= endDate
}
